import React from 'react'

const InfoSection = ({ image, title, text1, icon1, text2, icon2, reverse }) => (
  <div
    className={
      'columns is-vcentered cp-info-section' +
      (reverse === true ? ' is-flex-direction-row-reverse' : '')
    }
  >
    {reverse !== true && <div className="column is-1 is-wide-only"></div>}

    <div className="column is-4 cp-image">
      <img src={image} />
    </div>

    <div className="column is-1 is-wide-only"></div>

    <div className="column is-6 cp-content">
      <h3>{title}</h3>

      <div className="columns">
        <div className="column is-half">
          <img src={icon1} alt="icon" />

          <p>{text1}</p>
        </div>

        <div className="column is-half">
          <img src={icon2} alt="icon" />

          <p>{text2}</p>
        </div>
      </div>
    </div>

    {reverse === true && <div className="column is-1 is-wide-only"></div>}
  </div>
)

export default InfoSection
