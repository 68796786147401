import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout, { PreFooter } from '../components/Layout'
import SEO from '../components/seo'
import InfoSection from '../components/InfoSection'

import { BsChevronRight, BsChevronLeft } from 'react-icons/bs'

import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import PAGE_CONTENTS from '../../content/pages/for-media.json'

import MEDIA_PARTNER_1 from '../img/media_partners/inverted/cox_media_group.png'
import MEDIA_PARTNER_2 from '../img/media_partners/inverted/debmar_mercury.png'
import MEDIA_PARTNER_3 from '../img/media_partners/inverted/family_feud.png'
import MEDIA_PARTNER_4 from '../img/media_partners/inverted/fox_soul.png'
import MEDIA_PARTNER_5 from '../img/media_partners/inverted/fremantle.png'
import MEDIA_PARTNER_6 from '../img/media_partners/inverted/hubbard_broadcasting.png'
import MEDIA_PARTNER_7 from '../img/media_partners/inverted/Lx.png'
import MEDIA_PARTNER_8 from '../img/media_partners/inverted/meredith.png'
import MEDIA_PARTNER_9 from '../img/media_partners/inverted/morris_broadcasting.png'
import MEDIA_PARTNER_10 from '../img/media_partners/inverted/NBC.png'
import MEDIA_PARTNER_11 from '../img/media_partners/inverted/right_this_minute.png'
import MEDIA_PARTNER_12 from '../img/media_partners/inverted/sinclair_broadcasting_group.png'
import MEDIA_PARTNER_13 from '../img/media_partners/inverted/telemundo.png'
import MEDIA_PARTNER_14 from '../img/media_partners/inverted/the_weather_channel.png'
import MEDIA_PARTNER_15 from '../img/media_partners/inverted/abc.png'

const MEDIA_PARTNERS = [
  MEDIA_PARTNER_1,
  MEDIA_PARTNER_2,
  MEDIA_PARTNER_3,
  MEDIA_PARTNER_4,
  MEDIA_PARTNER_5,
  MEDIA_PARTNER_6,
  MEDIA_PARTNER_7,
  MEDIA_PARTNER_8,
  MEDIA_PARTNER_9,
  MEDIA_PARTNER_10,
  MEDIA_PARTNER_11,
  MEDIA_PARTNER_12,
  MEDIA_PARTNER_13,
  MEDIA_PARTNER_14,
  MEDIA_PARTNER_15,
]

const ForMedia = props => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title

  const slider = React.useRef(null)

  const settings = {
    arrows: false,
    dots: true,
    fade: true,
    infinite: true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    appendDots: dots => (
      <div>
        <div className="cp-slider-control">
          <a
            href="#"
            onClick={e => {
              e.preventDefault()

              slider.current.slickPrev()
            }}
            className="cp-arrow-left"
          >
            <BsChevronLeft />
          </a>

          <ul style={{ margin: '0px' }}> {dots} </ul>

          <a
            href="#"
            onClick={e => {
              e.preventDefault()

              slider.current.slickNext()
            }}
            className="cp-arrow-right"
          >
            <BsChevronRight />
          </a>
        </div>
      </div>
    ),
  }

  return (
    <Layout
      location={props.location}
      title={siteTitle}
      HeaderExtension={
        <div className="header_extension no_bg press aligncenter">
          <div className="bg">
            <div className="container">
              <div className="columns post-single ui-grid">
                <div className="column is-8">
                  <Fade bottom duration={1000} delay={125} distance={'25%'}>
                    <h2>Shoppable content your audience wants</h2>
                  </Fade>
                </div>
              </div>

              <Fade bottom duration={1000} delay={250} distance={'25%'}>
                <p>Cue your new revenue stream</p>
              </Fade>

              <Fade bottom duration={1000} delay={375} distance={'25%'}>
                <Link to="/contact" className="button">
                  Get Knocking
                </Link>
              </Fade>
            </div>
          </div>
        </div>
      }
    >
      <SEO
        title="For media"
        keywords={['Ecommerce solution', 'Revenue streams']}
        description="Find out how our innovative ecommerce solution helps media networks unlock new revenue streams"
      />

      <div className="page-content pages-sf">
        <div className="container">
          {(PAGE_CONTENTS.infoSections || []).map((infoSection, i) => (
            <InfoSection key={i} {...infoSection} reverse={i % 2 !== 0} />
          ))}
        </div>
      </div>

      <div className="page-content pages-index pages-about pages-shows">
        <div className="section-desc" style={{ background: 'transparent' }}>
          <div className="container">
            {' '}
            <div className="columns is-vcentered is-multiline is-mobile cp-logos">
              {MEDIA_PARTNERS.slice(0, 8).map((image, i) => (
                <div
                  className="column is-6-mobile is-4-tablet is-narrow-desktop is-narrow-widescreen is-narrow-fullhd"
                  key={`mp-${i}`}
                >
                  <Fade
                    effect="fadeInUp"
                    effectOut="fadeOutLeft"
                    delay={62.5 * i}
                  >
                    <img src={image} />
                  </Fade>
                </div>
              ))}
            </div>
            <div className="columns is-vcentered is-multiline is-mobile cp-logos-7">
              {MEDIA_PARTNERS.slice(8).map((image, i) => (
                <div
                  className="column is-6-mobile is-4-tablet is-narrow-desktop is-narrow-widescreen is-narrow-fullhd"
                  key={`mp-${i}`}
                >
                  <Fade
                    effect="fadeInUp"
                    effectOut="fadeOutLeft"
                    delay={62.5 * i}
                  >
                    <img src={image} />
                  </Fade>
                </div>
              ))}
            </div>
            <div className="columns is-vcentered is-multiline is-mobile cp-logos-thin">
              {MEDIA_PARTNERS.map((image, i) => (
                <div
                  className="column is-6-mobile is-4-tablet is-narrow-desktop is-narrow-widescreen is-narrow-fullhd"
                  key={`mp-${i}`}
                >
                  <Fade
                    effect="fadeInUp"
                    effectOut="fadeOutLeft"
                    delay={62.5 * i}
                  >
                    <img src={image} />
                  </Fade>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="page-content pages-sf">
        <div className="container">
          <Slider className="cp-slider" {...settings} ref={slider}>
            {(PAGE_CONTENTS.testimonials || []).map((slide, i) => (
              <div key={`slide-${i}`}>
                <p className="cp-text">{slide.text}</p>

                <div className="cp-prs">
                  {slide.image && <img src={slide.image} />}

                  <p className="cp-name">{slide.name}</p>
                  <p className="cp-company">{slide.company}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <PreFooter
        title="Let’s launch your new revenue channels"
        linkText="Get started"
        linkTo="/contact"
      />
    </Layout>
  )
}

export default ForMedia

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "press" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
